import { HStack } from '@sgme/ui';
import clsx from 'clsx';
import type { PropsWithChildren } from 'react';

interface MarginWrapperProps extends PropsWithChildren {
  isRowNotSaved: boolean;
  marginUnit: string;
  isReadOnly: boolean;
  onDoubleClick?: () => void;
}

export const MarginWrapper = ({
  children,
  isRowNotSaved,
  marginUnit,
  onDoubleClick,
  isReadOnly,
}: MarginWrapperProps) => {
  const Child = (
    <HStack gap="16px" horizontalAlignment="start">
      {children}
      <span className={clsx('text-secondary', 'fw-bold', isRowNotSaved && 'text-info')}>{marginUnit}</span>
    </HStack>
  );

  if (isReadOnly) {
    return Child;
  }

  return (
    <button type="button" className="btn btn-flat-primary" onDoubleClick={onDoubleClick}>
      {Child}
    </button>
  );
};
