import type { ColDef, ValueFormatterParams } from '@ag-grid-community/core';
import { ActionsCellRenderer } from '@pages/fx/FxProductsPage/CashTab/cells/ActionsCellRenderer';
import { CurrencyCellRenderer } from '@pages/fx/FxProductsPage/CashTab/cells/CurrencyCellRenderer';
import { type IntlFormatters, useIntl } from 'react-intl';
import { HelperCellHeader } from '../../components/HelperCellHeader';
import { DefaultCell } from './cells/DefaultCell';
import { InstrumentGroupCell } from './cells/InstrumentGroupCell';
import { MarginBidCell } from './cells/Margin/MarginBidCell';
import { MarginBidAskHeader } from './cells/Margin/MarginBidAskHeader';
import type { FxCashGridDataType } from '@services/fx/model/cash';
import { TieringCell } from './cells/TieringCell';
import { MarginAskCell } from './cells/Margin/MarginAskCell';
import { MarginBidAskTieringCell } from './cells/Margin/MarginBidAskTieringCell';
import { MarginAskHeader } from './cells/Margin/MarginAskHeader';
import { MarginBidHeader } from './cells/Margin/MarginBidHeader';

export const useColumnDefs = (
  isGroupedByInstrument: boolean,
  gridDataType?: FxCashGridDataType,
  isEditing = false,
): ColDef[] => {
  const { formatMessage } = useIntl();

  return isEditing
    ? getEditColumnDefs(formatMessage, isGroupedByInstrument, gridDataType)
    : getViewColumnDefs(formatMessage, isGroupedByInstrument, undefined);
};

const getViewColumnDefs = (
  formatMessage: IntlFormatters['formatMessage'],
  isGroupedByInstrument: boolean,
  gridDataType: FxCashGridDataType | undefined,
) => {
  const columns: ColDef[] = [
    {
      field: 'instrument',
      headerName: formatMessage({ id: 'fx.CashGrid.column.instrument' }),
      rowGroup: isGroupedByInstrument, // required to group rows by this field
      hide: true,
      cellRenderer: InstrumentGroupCell,
    },
    {
      field: 'venue',
      headerName: formatMessage({ id: 'fx.CashGrid.column.venue' }),
      cellRenderer: DefaultCell,
    },
    {
      field: 'clientVenueLogin',
      headerName: formatMessage({ id: 'fx.CashGrid.column.clientVenueLogin' }),
      cellRenderer: DefaultCell,
    },
    {
      field: 'currency',
      headerComponent: HelperCellHeader,
      headerComponentParams: {
        helperLocaleKey: 'fx.message.tooltip.currency',
      },
      cellRenderer: CurrencyCellRenderer,
    },
    {
      field: 'onshoreOffshore',
      headerName: formatMessage({ id: 'fx.CashGrid.column.onshorOffshore' }),
      cellRenderer: DefaultCell,
      valueFormatter: onshoreOffshoreValueFormatter,
    },
    // {
    //   field: 'amount',
    //   headerName: formatMessage({ id: 'fx.CashGrid.column.amount' }),
    //   cellRenderer: DefaultCell,
    //   minWidth: 168,
    // },
    {
      field: 'tenor',
      headerName: formatMessage({ id: 'fx.CashGrid.column.tenor' }),
      cellRenderer: DefaultCell,
    },
  ];

  if (gridDataType === 'tiering-grid-data-type') {
    columns.push({
      field: 'marginsBidAskOrTiering',
      cellRenderer: TieringCell,
      headerComponent: MarginBidAskHeader,
      minWidth: 227,
    });
  } else if (gridDataType === 'margin-grid-data-type') {
    columns.push({
      field: 'marginsBidAskOrTiering',
      cellRenderer: MarginBidCell,
      headerComponent: MarginBidHeader,
      suppressKeyboardEvent: () => true,
      autoHeight: true,
      minWidth: 227,
      colId: 'marginsBid',
    });
    columns.push({
      field: 'marginsBidAskOrTiering',
      cellRenderer: MarginAskCell,
      headerComponent: MarginAskHeader,
      suppressKeyboardEvent: () => true,
      autoHeight: true,
      minWidth: 227,
      colId: 'marginsAsk',
    });
  } else {
    columns.push({
      field: 'marginsBidAskOrTiering',
      cellRenderer: MarginBidAskTieringCell,
      headerComponent: MarginBidAskHeader,
      minWidth: 227,
    });
  }

  return columns;
};

const selectionColDef: ColDef = {
  field: 'selections',
  headerValueGetter: '',
  headerCheckboxSelection: true,
  showDisabledCheckboxes: true,
  maxWidth: 50,
  pinned: 'left',
  checkboxSelection: true,
};

const actionsColDef: ColDef = {
  field: 'actions',
  pinned: 'right',
  width: 125,
  cellRenderer: ActionsCellRenderer,
};

const getEditColumnDefs = (
  formatMessage: IntlFormatters['formatMessage'],
  isGroupedByInstrument: boolean,
  gridDataType: FxCashGridDataType | undefined,
): ColDef[] => [
  selectionColDef,
  ...getViewColumnDefs(formatMessage, isGroupedByInstrument, gridDataType),
  actionsColDef,
];

const onshoreOffshoreValueFormatter = ({ value, context }: ValueFormatterParams) => {
  if ('True' === value) {
    return context.formatMessage({ id: 'fx.editProfile.fx-cash-rfq-perimeter.shoreSelect.onshore' });
  }
  if ('False' === value) {
    return context.formatMessage({ id: 'fx.editProfile.fx-cash-rfq-perimeter.shoreSelect.offshore' });
  }
  return value;
};
