import { AgGridReact } from '@ag-grid-community/react';
import { useColumnDefs } from './useColumnDefs';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import type { ColDef, GridOptions, IRowNode, RowClassParams } from '@ag-grid-community/core';
import { useIntl } from 'react-intl';
import type { GridCashProfileRow } from './useData';
import { applySpecification } from '@utils/object';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { selectRows } from '@store/fxProfileEdition/fxProfileEditionSlice';
import type { FxOrderProfileRow } from '@services/fx/model/order';
import { ModuleRegistry } from '@ag-grid-community/core';
import { isDefined } from '@sgme/fp';
import type { FxCashGridDataType } from '@services/fx/model/cash';

type CashGridProps = {
  rows: GridCashProfileRow[];
  isGroupedByInstrument: boolean;
  isEditing?: boolean;
  // gridDataType === undefined => one column for presenting margins and tiering
  // gridDataType === tiering-grid-data-type => one column for tiering (readonly), no column for margins
  // gridDataType === margin-grid-data-type => no column for tiering, two columns for margins (bid and ask)
  gridDataType?: FxCashGridDataType;
};

interface Event {
  api: { getSelectedRows: () => FxOrderProfileRow[] };
}

ModuleRegistry.registerModules([ClientSideRowModelModule, ClipboardModule, RowGroupingModule, MenuModule]);

export const CashGrid = forwardRef<AgGridReact, CashGridProps>((props, ref) => {
  const { rows: allRows, isGroupedByInstrument, isEditing = false, gridDataType } = props;
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useDispatch();
  // biome-ignore lint/style/noNonNullAssertion: i dont like red
  useImperativeHandle(ref, () => gridRef.current!, []);

  const { formatMessage } = useIntl();

  const columnDefs = useColumnDefs(isGroupedByInstrument, gridDataType, isEditing);

  const onRowSelected = (event: Event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedRowsId = selectedRows?.map((row) => row.uiRowId);

    //there will always be a row selected while dispatching
    dispatch(selectRows({ uiRowsId: selectedRowsId as number[] })); /**/
  };

  return (
    <div className="flex-grow-1 ag-theme-sg-bootstrap ag-theme-fx">
      <AgGridReact
        ref={gridRef}
        gridOptions={GRID_OPTIONS}
        defaultColDef={DEFAULT_COLUMN_DEF}
        autoGroupColumnDef={AUTO_GROUP_COLUMN_DEF}
        columnDefs={columnDefs}
        context={{ formatMessage }}
        rowData={allRows}
        className="w-100"
        groupDisplayType={'multipleColumns'} // required to show the group column name as the used field
        groupDefaultExpanded={1}
        onRowSelected={onRowSelected}
        // quickFilterText={query}
      />
    </div>
  );
});

CashGrid.displayName = 'CashGrid';

const GRID_OPTIONS: GridOptions = {
  headerHeight: 40,
  rowHeight: 40,
  suppressContextMenu: true,
  suppressMovableColumns: true,
  suppressCellFocus: true,
  suppressRowClickSelection: true,
  suppressMultiSort: true,
  rowSelection: 'multiple',
  enableRangeSelection: false,
  enableFillHandle: false,
  animateRows: true,

  getRowStyle: applySpecification({
    color: ({ data }: RowClassParams<GridCashProfileRow>) =>
      isDefined(data) && data?.state !== 'untouched' ? 'text-info !important' : undefined,
    fontWeight: ({ data }: RowClassParams<GridCashProfileRow>) =>
      (data?.isLastRowOfType ?? false) ? 'bold' : undefined,
  }),
  getRowClass: ({ node }: RowClassParams<GridCashProfileRow>) =>
    clsx({
      'fw-bold': Boolean(node?.group),
    }),
  //last row is not selectable for edition
  isRowSelectable: ({ data }: IRowNode<GridCashProfileRow>) => data?.isLastRowOfType !== true,
};

const DEFAULT_COLUMN_DEF: ColDef = {
  flex: 1,
  lockPinned: true,
  sortable: false,
  filter: false,
  resizable: true,
  enableCellChangeFlash: true,
  menuTabs: ['filterMenuTab', 'columnsMenuTab'],
  filterParams: { newRowAction: 'keep' },
  headerClass: 'text-secondary',
};

// instrument column min width
const AUTO_GROUP_COLUMN_DEF = {
  flex: 1,
  minWidth: 500,
  cellRendererParams: {
    suppressCount: true,
  },
};
