import { isNotDefined } from '@sgme/fp';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import type { GridCashProfileRow } from '../../useData';
import type { IRowNode } from '@ag-grid-community/core';
import { type ChangeEvent, type KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { applyMarginValue } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { MarginWrapper } from './MarginWrapper';

export const MarginAskCell = ({
  value,
  node,
}: {
  value: GridCashProfileRow['marginsBidAskOrTiering'];
  node: IRowNode<GridCashProfileRow>;
}) => {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState<string>();

  const isReadOnly = useAppSelector((state) => state.fxProfileEdition.isEditingRow);

  if (isNotDefined(value)) {
    return undefined;
  }

  const isRowNotSaved = node.data?.state !== 'untouched';

  if (value.isRejected) {
    return (
      <span className={clsx('fw-bold', 'text-secondary', isRowNotSaved && 'text-info')}>
        <FormattedMessage id="fx.CashGrid.column.marginsBidAsk.rejected" />
      </span>
    );
  }

  if (value.type === 'tiering') {
    return '!!!';
  }

  useEffect(() => {
    inputRef.current?.focus();
  });

  const AllowInputModification = isEditing && !isReadOnly;

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    dispatch(
      applyMarginValue({
        modifiedRowId: node.data?.uiRowId ?? 0,
        marginType: 'ask',
        marginValue: e.target.value !== '' ? e.target.value : '0',
      }),
    );
    setIsEditing(false);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur();
    }
  };

  const authorizedCharactersRegex = /^-?[0-9]*[.,]?[0-9]*$/;
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    if (authorizedCharactersRegex.test(value)) {
      setInputValue(value);
    }
  }

  return (
    <MarginWrapper
      isRowNotSaved={isRowNotSaved}
      marginUnit={value.marginUnit}
      isReadOnly={isReadOnly}
      onDoubleClick={() => setIsEditing(true)}
    >
      {AllowInputModification ? (
        <input
          ref={inputRef}
          className="form-control"
          type="text"
          onBlur={handleBlur}
          style={{ width: '5rem' }}
          value={inputValue ?? value.marginAskValue}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />
      ) : (
        <div
          style={{ width: '5rem' }}
          className={clsx('d-flex align-items-center ms-16px', 'text-pink-70', 'fw-bold', isRowNotSaved && 'text-info')}
        >
          {value.marginAskValue}
        </div>
      )}
    </MarginWrapper>
  );
};
