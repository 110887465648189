import { FormattedMessage } from 'react-intl';

export const MarginAskHeader = () => (
  <>
    <FormattedMessage id="fx.CashGrid.column.marginsBidAsk.margins" />
    &nbsp;
    <span className="text-pink-70 fw-bold">
      <FormattedMessage id="fx.CashGrid.column.marginsBidAsk.ask" />
    </span>
  </>
);
