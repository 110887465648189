import { FormattedMessage } from 'react-intl';

export const MarginBidHeader = () => (
  <>
    <FormattedMessage id="fx.CashGrid.column.marginsBidAsk.margins" />
    &nbsp;
    <span className="text-turquoise-70 fw-bold">
      <FormattedMessage id="fx.CashGrid.column.marginsBidAsk.bid" />
    </span>
  </>
);
