import { isDefined } from '@sgme/fp';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { InternalRowId } from '@services/fx/model/models';
import {
  type FxProfileColumns,
  isFxAlgoOrderProfileColumns,
  isFxCashProfileColumns,
  isFxCashProfileMarginColumns,
  isFxCashProfileRejectedColumns,
  isFxCashProfileTieringColumns,
  isFxFixingOrderProfileColumns,
  isFxLimitOrderProfileColumns,
} from '@services/fx/model/perimiters';
import type {
  FxProfileMarginGridRow,
  FxUpdatedProfileMarginGridRow,
  RowState,
} from '@services/fx/model/profile-margin-grid';
import type {
  FxCashProductKey,
  FxCashProfileColumns,
  FxCashProfileMarginColumns,
  FxCashProfileRejectedColumns,
  FxCashProfileRow,
  FxCashProfileTieringColumns,
} from '@services/fx/model/cash';
import type {
  FxOrderProductKey,
  FxOrderProfileAlgoColumns,
  FxOrderProfileBaseColumns,
  FxOrderProfileFixingColumns,
  FxOrderProfileLimitColumns,
  FxOrderProfileRowV2,
} from '@services/fx/model/order';

// ███████╗████████╗ █████╗ ████████╗███████╗
// ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██╔════╝
// ███████╗   ██║   ███████║   ██║   █████╗
// ╚════██║   ██║   ██╔══██║   ██║   ██╔══╝
// ███████║   ██║   ██║  ██║   ██║   ███████╗
// ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝   ╚══════╝

type ProfileEditionState = {
  allRowUiIds: string[];
  rowByUiId: Record<string, FxUpdatedProfileMarginGridRow<FxProfileColumns>>;
  nextUiId: number;

  editingRow?: EditingRow;
  isEditingRow: boolean;

  selectedRowIds: number[];
  shouldKeepEditingRow: boolean;
};

export type EditingRow = {
  internalRowId: InternalRowId;
  uiRowId: number;
  state: RowState;
  columns: FxCashProfileRow['columns'] | FxOrderProfileRowV2['columns'];

  validationError?: {
    code: number;
    description: string;
  };
};

export const initialState: ProfileEditionState = {
  allRowUiIds: [],
  rowByUiId: {},
  nextUiId: 0,

  // gridDataType: 'margin-grid-data-type',
  editingRow: undefined,
  isEditingRow: false,
  selectedRowIds: [],
  shouldKeepEditingRow: false,
};

export type SetupType = 'rejection' | 'margin' | 'tiering';

// ███████╗██╗     ██╗ ██████╗███████╗
// ██╔════╝██║     ██║██╔════╝██╔════╝
// ███████╗██║     ██║██║     █████╗
// ╚════██║██║     ██║██║     ██╔══╝
// ███████║███████╗██║╚██████╗███████╗
// ╚══════╝╚══════╝╚═╝ ╚═════╝╚══════╝

export type CashRowtype = 'cash-margin' | 'cash-tiering' | 'cash-rejection';
type CreateCashRowPayloadAction = {
  type: CashRowtype;
  productKey: FxCashProductKey;
};
type CreateOrderRowPayloadAction = {
  type: 'order-margin';
  productKey: FxOrderProductKey;
};

export const fxProfileEditionSlice = createSlice({
  name: 'fxProfileEdition',
  initialState,
  reducers: {
    initRows: (
      state,
      action: PayloadAction<{
        rows: FxProfileMarginGridRow<FxProfileColumns>[];
        editingRow?: EditingRow;
        shouldKeepEditingRow: boolean;
      }>,
    ) => {
      const { rows, editingRow, shouldKeepEditingRow } = action.payload;

      return {
        allRowUiIds: rows.map((_row, index) => index.toString()),
        rowByUiId: rows.reduce(
          (acc, row, uiRowId) => {
            acc[uiRowId] = {
              ...row,
              uiRowId,
              state: 'untouched',
            };

            return acc;
          },
          {} as Record<string, FxUpdatedProfileMarginGridRow<FxProfileColumns>>,
        ),
        nextUiId: rows.length,
        // if there is already an editing row state during page initialization,
        // this means the user has just created a preset and we initiated the edition
        // of the corresponding row for the user to see it in edit mode directly
        // therefore we don't want to let it being overriden by the page init
        // we have shouldKeepEditingRow to make sure that the edition form is reset properly
        // when user navigates away
        isEditingRow: state.isEditingRow,
        editingRow:
          isDefined(state.editingRow) && editingRow === undefined && state.shouldKeepEditingRow
            ? state.editingRow
            : editingRow,
        shouldKeepEditingRow,
        selectedRowIds: [],
      };
    },

    editRow: (state, action: PayloadAction<{ uiRowId: number }>) => ({
      ...state,
      isEditingRow: true,
      editingRow: {
        ...state.rowByUiId[action.payload.uiRowId],
        state: 'touched',
      },
    }),

    // could not get rid of the type error despite covering all
    // combination of type / productKey
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    createRow: (state, action: PayloadAction<CreateCashRowPayloadAction | CreateOrderRowPayloadAction>) => {
      const { type, productKey } = action.payload;

      let columns = undefined;

      switch (type) {
        case 'cash-rejection':
          columns = INITIAL_CASH_REJECTION_COLUMN;
          break;
        case 'cash-margin':
          columns = INITIAL_CASH_MARGIN_COLUMN;
          break;
        case 'cash-tiering':
          columns = getInitialTieringColumns(productKey);
          break;
        case 'order-margin':
          if (productKey === 'algo-order-product') {
            columns = INITIAL_ALGO_ORDER_COLUMN;
          } else if (productKey === 'limit-order-product') {
            columns = INITIAL_LIMIT_ORDER_COLUMN;
          } else if (productKey === 'fixing-order-product') {
            columns = INITIAL_FIXING_ORDER_COLUMN;
          }
          break;
      }

      return {
        ...state,

        nextUiId: state.nextUiId + 1,
        isEditingRow: true,
        editingRow: {
          internalRowId: [`${state.nextUiId}`],
          uiRowId: state.nextUiId,
          state: 'added',
          columns: columns!,
          validationError: undefined,
        },
      };
    },

    duplicateRow: (state, action: PayloadAction<{ uiRowId: number }>) => {
      const dupplicatedRowColumns = state.rowByUiId[action.payload.uiRowId].columns;

      return {
        ...state,

        nextUiId: state.nextUiId + 1,

        isEditingRow: true,
        editingRow: {
          internalRowId: [`${state.nextUiId}`],
          uiRowId: state.nextUiId,
          state: 'added',
          columns: dupplicatedRowColumns,
          validationError: undefined,
        },
      };
    },

    selectRows: (state, action: PayloadAction<{ uiRowsId: number[] }>) => {
      state.selectedRowIds = action.payload.uiRowsId;
      return state;
    },

    deleteRows: (state, action: PayloadAction<{ uiRowsId: number[] }>) => {
      action.payload.uiRowsId.forEach((rowId) => {
        state.rowByUiId[rowId].state = 'deleted';
      });

      return state;
    },

    applyValidatedRow: (state, action: PayloadAction<{ validatedRows: EditingRow[] }>) => ({
      ...state,
      allRowUiIds: action.payload.validatedRows.map((_row, index) => index.toString()),
      rowByUiId: action.payload.validatedRows.reduce(
        (acc, row, uiRowId) => {
          acc[uiRowId] = {
            ...row,
            uiRowId,
          };

          return acc;
        },
        {} as Record<string, FxUpdatedProfileMarginGridRow<FxProfileColumns>>,
      ),
      isEditingRow: false,
      editingRow: undefined,
    }),

    applyMarginValue: (
      state,
      action: PayloadAction<{ marginType: 'bid' | 'ask'; marginValue: string; modifiedRowId: number }>,
    ) => {
      const row = state.rowByUiId[action.payload.modifiedRowId];
      row.state = 'touched';
      (row.columns as FxCashProfileMarginColumns)[
        action.payload.marginType === 'bid' ? 'marginBidValue' : 'marginAskValue'
      ] = action.payload.marginValue;
    },

    cancelRowEditing: (state) => ({
      ...state,

      isEditingRow: false,
      editingRow: undefined,
    }),

    changeColumnMediaDimension: (
      state,
      action: PayloadAction<{
        key: keyof FxProfileColumns;
        value: string;
      }>,
    ) => {
      if (
        isFxCashProfileColumns(state.editingRow?.columns) ||
        isFxAlgoOrderProfileColumns(state.editingRow?.columns) ||
        isFxFixingOrderProfileColumns(state.editingRow?.columns) ||
        isFxLimitOrderProfileColumns(state.editingRow?.columns)
      ) {
        state.editingRow.columns = {
          ...state.editingRow.columns,
          [action.payload.key]: action.payload.value,
        };
      }

      return state;
    },

    // FX-CASH-COMMON ACTIONS

    // setup
    changeSetupType: (state, action: PayloadAction<{ type: SetupType; productKey: FxCashProductKey }>) => {
      const type = action.payload.type;
      if (isDefined(state.editingRow)) {
        const columns = state.editingRow.columns;

        switch (type) {
          case 'rejection': {
            if (isFxCashProfileMarginColumns(columns)) {
              const { marginBidValue, marginAskValue, marginUnit, ...editingRowWithoutMarginFields } = columns;
              state.editingRow.columns = {
                ...editingRowWithoutMarginFields,
                isRejected: 'True',
              };
            }
            if (isFxCashProfileTieringColumns(columns)) {
              const { tieringResult, ...editingRowWithoutTieringFields } = columns;
              state.editingRow.columns = {
                ...editingRowWithoutTieringFields,
                isRejected: 'True',
              };
            }
            break;
          }

          case 'margin': {
            const marginFields = {
              marginBidValue: INITIAL_CASH_MARGIN_COLUMN.marginBidValue,
              marginAskValue: INITIAL_CASH_MARGIN_COLUMN.marginAskValue,
              marginUnit: INITIAL_CASH_MARGIN_COLUMN.marginUnit,
            };
            // can only switch to margin from a rejection but we're doing the check to properly type it
            if (isFxCashProfileRejectedColumns(state.editingRow.columns)) {
              state.editingRow.columns = {
                ...state.editingRow.columns,
                isRejected: 'False',
                ...marginFields,
              };
            }
            break;
          }

          case 'tiering': {
            const tieringFields = {
              tieringResult: getInitialTieringColumns(action.payload.productKey).tieringResult,
            };
            // can only switch to tiering from a rejection but we're doing the check to properly type it
            if (isFxCashProfileRejectedColumns(state.editingRow?.columns)) {
              state.editingRow.columns = {
                ...state.editingRow.columns,
                isRejected: 'False',
                ...tieringFields,
              };
            }
            break;
          }
        }
      }
      return state;
    },

    changeCashColumnDimension: (
      state,
      action: PayloadAction<{
        key: keyof FxCashProfileColumns;
        value: string;
      }>,
    ) => {
      if (isFxCashProfileColumns(state.editingRow?.columns)) {
        state.editingRow.columns = {
          ...state.editingRow.columns,
          [action.payload.key]: action.payload.value,
        };
      }

      return state;
    },

    // FX-CASH-MARGIN
    changeMarginColumnValue: (
      state,
      action: PayloadAction<{
        key: keyof FxCashProfileMarginColumns;
        value: string;
      }>,
    ) => {
      if (isFxCashProfileMarginColumns(state.editingRow?.columns)) {
        state.editingRow.columns = {
          ...state.editingRow.columns,
          [action.payload.key]: action.payload.value,
        };
      }
      return state;
    },

    // FX-CASH-TIERING

    changeTieringResult: (state, action: PayloadAction<{ value: string }>) => {
      if (isFxCashProfileTieringColumns(state.editingRow?.columns)) {
        state.editingRow.columns.tieringResult = action.payload.value;
      }
      return state;
    },

    // FX-ORDER-ACTIONS
    changeOrderBaseColumn: (
      state,
      action: PayloadAction<{
        key: keyof FxOrderProfileBaseColumns;
        value: string;
      }>,
    ) => {
      if (
        isFxAlgoOrderProfileColumns(state.editingRow?.columns) ||
        isFxLimitOrderProfileColumns(state.editingRow?.columns) ||
        isFxFixingOrderProfileColumns(state.editingRow?.columns)
      ) {
        state.editingRow.columns[action.payload.key] = action.payload.value;
      }
      return state;
    },

    changeAlgoOrderColumn: (
      state,
      action: PayloadAction<{
        key: keyof Omit<FxOrderProfileAlgoColumns, 'marginUnit'>;
        value: string;
      }>,
    ) => {
      if (isFxAlgoOrderProfileColumns(state.editingRow?.columns)) {
        state.editingRow.columns[action.payload.key] = action.payload.value;
      }
      return state;
    },

    changeLimitOrderColumn: (
      state,
      action: PayloadAction<{
        key: keyof Omit<FxOrderProfileLimitColumns, 'marginUnit'>;
        value: string;
      }>,
    ) => {
      if (isFxLimitOrderProfileColumns(state.editingRow?.columns)) {
        state.editingRow.columns[action.payload.key] = action.payload.value;
      }
      return state;
    },

    changeFixingOrderColumn: (
      state,
      action: PayloadAction<{
        key: keyof Omit<FxOrderProfileFixingColumns, 'marginUnit'>;
        value: string;
      }>,
    ) => {
      if (isFxFixingOrderProfileColumns(state.editingRow?.columns)) {
        state.editingRow.columns[action.payload.key] = action.payload.value;
      }
      return state;
    },

    changeMarginValue: (state, action: PayloadAction<{ value: string }>) => {
      if (
        isFxLimitOrderProfileColumns(state.editingRow?.columns) ||
        isFxFixingOrderProfileColumns(state.editingRow?.columns)
      ) {
        state.editingRow.columns.marginValue = action.payload.value;
      }
      return state;
    },
  },
});

export const {
  initRows,
  editRow,
  createRow,
  duplicateRow,
  deleteRows,
  selectRows,

  changeColumnMediaDimension,

  // CASH
  changeSetupType,
  changeCashColumnDimension,

  // margin
  changeMarginColumnValue,

  // tiering
  changeTieringResult,

  // ORDER
  changeOrderBaseColumn,
  changeLimitOrderColumn,
  changeMarginValue,
  changeFixingOrderColumn,
  changeAlgoOrderColumn,

  applyValidatedRow,
  applyMarginValue,
  cancelRowEditing,
} = fxProfileEditionSlice.actions;

export const INITIAL_CASH_MARGIN_COLUMN: FxCashProfileMarginColumns = {
  mediaDimension: '*', // venue
  extClientLoginDimension: '*', // clientVenueLogin
  onshoreOffshoreDimension: '*', // onshoreOffshore

  // currency -> same as order
  currencyPairDimension: '*', // single (CUR1), pair (CUR1/CUR2), group (from a list like G10) and All (*)
  applyOnInvertedPairDimension: 'False', // False if pips and currency pair (and disabled)

  // amount
  amountStartDimension: '*',
  amountEndDimension: '*',
  amountCurrencyDimension: '*',

  // tenor
  tenorStartDimension: '*',
  tenorEndDimension: '*',

  marginBidValue: '0',
  marginAskValue: '0',
  marginUnit: 'bps', // pip / bps

  isRejected: 'False',
};

export const getInitialTieringColumns = (productKey: FxCashProductKey): FxCashProfileTieringColumns => ({
  mediaDimension: '*', // venue
  extClientLoginDimension: '*', // clientVenueLogin
  onshoreOffshoreDimension: '*', // onshoreOffshore

  // currency -> same as order
  currencyPairDimension: '*', // single (CUR1), pair (CUR1/CUR2), group (from a list like G10) and All (*)
  applyOnInvertedPairDimension: 'False', // False if pips and currency pair (and disabled)

  // amount
  amountStartDimension: '*',
  amountEndDimension: '*',
  amountCurrencyDimension: '*',

  // tenor
  tenorStartDimension: '*',
  tenorEndDimension: '*',

  tieringResult: productKey === 'spot-product' ? 'SPOT_AX' : 'G_STANDARD',

  isRejected: 'False',
});

export const INITIAL_CASH_REJECTION_COLUMN: FxCashProfileRejectedColumns = {
  mediaDimension: '*', // venue
  extClientLoginDimension: '*', // clientVenueLogin
  onshoreOffshoreDimension: '*', // onshoreOffshore

  // currency -> same as order
  currencyPairDimension: '*', // single (CUR1), pair (CUR1/CUR2), group (from a list like G10) and All (*)
  applyOnInvertedPairDimension: 'False', // False if pips and currency pair (and disabled)

  // amount
  amountStartDimension: '*',
  amountEndDimension: '*',
  amountCurrencyDimension: '*',

  // tenor
  tenorStartDimension: '*',
  tenorEndDimension: '*',

  isRejected: 'True',
};

export const INITIAL_ALGO_ORDER_COLUMN: FxOrderProfileAlgoColumns = {
  mediaDimension: '*', // venue
  instrumentDimension: '*',
  currencyPairDimension: '*', // single (CUR1), pair (CUR1/CUR2), group (from a list like G10) and All (*)
  marginUnit: 'bps',
  algoTypeDimension: '*',
  'liquidityProviderDimension.External.marginValue': '0',
  'liquidityProviderDimension.Internal.marginValue': '0',
};

export const INITIAL_LIMIT_ORDER_COLUMN: FxOrderProfileLimitColumns = {
  mediaDimension: '*', // venue
  instrumentDimension: '*',
  currencyPairDimension: '*', // single (CUR1), pair (CUR1/CUR2), group (from a list like G10) and All (*)
  marginUnit: 'pips',
  marginValue: '0',
  limitOrderTypeDimension: '*',
};

export const INITIAL_FIXING_ORDER_COLUMN: FxOrderProfileFixingColumns = {
  mediaDimension: '*', // venue
  instrumentDimension: '*',
  currencyPairDimension: '*', // single (CUR1), pair (CUR1/CUR2), group (from a list like G10) and All (*)
  marginUnit: 'bps',
  marginValue: '0',
  fixingSourceDimension: '*',
};
