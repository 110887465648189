import { isNotDefined } from '@sgme/fp';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import type { GridCashProfileRow } from '../useData';
import type { IRowNode } from '@ag-grid-community/core';

export const TieringCell = (props: {
  value: GridCashProfileRow['marginsBidAskOrTiering'];
  node: IRowNode<GridCashProfileRow>;
}) => {
  const { value, node } = props;

  if (isNotDefined(value)) {
    return undefined;
  }
  const isEdited = node.data?.state !== 'untouched';

  if (value.isRejected) {
    return (
      <span className={clsx('fw-bold', 'text-secondary', isEdited && 'text-info')}>
        <FormattedMessage id="fx.CashGrid.column.marginsBidAsk.rejected" />
      </span>
    );
  }

  if (value.type === 'margin') {
    return '!!!';
  }

  return <span className={clsx('fw-bold', isEdited && 'text-info')}>{value.tiering}</span>;
};
